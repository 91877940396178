//TODO: delete after endpoint integration
export const mockedProjects = [
  {
    projectId: '123',
    name: 'plexo1',
    certificatePath: '../certificates/px-1-certificate',
    instrumentPath: '../instruments/px-1-certificate',
    transactionPath: '../transations/px-1-certificate',
  },
  {
    projectId: '124',
    name: 'plexo2',
    certificatePath: '../certificates/px-2-certificate',
    instrumentPath: '../instruments/px-2-certificate',
    transactionPath: '../transations/px-2-certificate',
  },
  {
    projectId: '125',
    name: 'plexo3',
    certificatePath: '../certificates/px-3-certificate',
    instrumentPath: '../instruments/px-3-certificate',
    transactionPath: '../transations/px-3-certificate',
  },
];
