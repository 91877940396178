//TODO: delete after endpoint integration
export const mockedEnvironments = [
  {
    environmentId: '321',
    url: 'https://project.com/',
    alias: 'env1',
    projectId: '124',
  },
  {
    environmentId: '322',
    url: 'https://project.com/',
    alias: 'env2',
    projectId: '124',
  },
  {
    environmentId: '323',
    url: 'https://project.com/',
    alias: 'env3',
    projectId: '125',
  },
  {
    environmentId: '324',
    url: 'https://project.com/',
    alias: 'env4',
    projectId: '123',
  },
  {
    environmentId: '325',
    url: 'https://project.com/',
    alias: 'env5',
    projectId: '123',
  },
  {
    environmentId: '326',
    url: 'https://project.com/',
    alias: 'env6',
    projectId: '123',
  },
];
