import { createStyles, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomButton, { CustomButtonVariant } from '../components/common/buttons/CustomButton';
import ProjectForm from '../components/projects/ProjectForm';
import { Project } from '../types/project.types';
//TODO: delete after endpoint integration
import { mockedProjects } from '../data/mockedProjects';
import { texts } from '../data/texts';

interface ProjectParams {
  projectId?: string;
}

const useStyles = makeStyles(() => createStyles({ buttons: { display: 'flex', gap: 16 } }));

function ProjectDetail() {
  const classes = useStyles();
  const params = useParams<keyof ProjectParams>();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState<Project | null | undefined>(null);
  const formId: string = 'project-form-id';

  const cancel = () => navigate('/projects');

  useEffect(() => {
    const selectedProjectId = params.projectId;

    if (mockedProjects && selectedProjectId) {
      setSelectedProject(mockedProjects.find((project) => project.projectId === selectedProjectId));
    }
  }, [params.projectId]);

  return (
    <div className="project">
      <div className="project-title">
        <h3>{`${params.projectId ? 'Editar' : 'Nuevo'} proyecto`}</h3>
        <div className={classes.buttons}>
          <CustomButton type="submit" form={formId}>
            {texts.save}
          </CustomButton>
          <CustomButton onClick={cancel} variant={CustomButtonVariant.SECONDARY}>
            {texts.cancel}
          </CustomButton>
        </div>
      </div>
      <ProjectForm selectedProject={selectedProject!} formId={formId} />
    </div>
  );
}

export default ProjectDetail;
