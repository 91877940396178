import { Paper } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { Project } from '../../types/project.types';
import schemas from '../../data/schemas';
import { texts } from '../../data/texts';
import FormTextField from '../common/forms/FormTextField';

interface ProjectFormProps {
  selectedProject?: Project;
  formId?: string;
}

interface ProjectValues {
  name: string;
  certificatePath: string;
  instrumentPath: string;
  transactionPath: string;
}

function ProjectForm({ selectedProject, formId }: ProjectFormProps) {
  // const [submitting, setSubmitting] = useState<boolean>(false);

  const submitProject = (values: ProjectValues) => console.log('TODO');

  return (
    <Paper elevation={0} className="paper">
      <Formik
        initialValues={{
          name: selectedProject ? selectedProject.name : '',
          certificatePath: selectedProject ? selectedProject.certificatePath : '',
          instrumentPath: selectedProject ? selectedProject.instrumentPath : '',
          transactionPath: selectedProject ? selectedProject.transactionPath : '',
        }}
        validationSchema={schemas.ProjectSchema}
        onSubmit={submitProject}
        enableReinitialize
      >
        <Form id={formId} className="form">
          <div className="form-row">
            <Field label={texts.name} name="name" component={FormTextField} type="text" />
            <Field
              label={texts.certificatePath}
              name="certificatePath"
              component={FormTextField}
              type="text"
            />
          </div>
          <div className="form-row">
            <Field label={texts.instrumentPath} name="instrumentPath" component={FormTextField} type="text" />
            <Field
              label={texts.transactionPath}
              name="transactionPath"
              component={FormTextField}
              type="text"
            />
          </div>
        </Form>
      </Formik>
    </Paper>
  );
}

export default ProjectForm;
