import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { texts } from '../data/texts';
import CustomButton, { CustomButtonVariant } from '../components/common/buttons/CustomButton';
import Loading from '../components/common/animations/Loading';
import EnvironmentsTable from '../components/environments/EnvironmentsTable';
import { Environment } from '../types/environment.types';
import { Project } from '../types/project.types';
import { mockedEnvironments } from '../data/mockedEnvironments';
import { mockedProjects } from '../data/mockedProjects';

function Environments() {
  const navigate = useNavigate();
  const params = useParams();
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [projectEnvironments, setProjectEnvironments] = useState<Environment[] | null>(null);

  const backToList = () => navigate('/projects');

  const newEnvironment = () => navigate('/environments/create');

  const openEnvironment = (environment: Environment) => {
    navigate(`/environments/${environment.environmentId}/update`);
  };

  useEffect(() => {
    const selectedProjectId = params.projectId;

    if (mockedProjects && selectedProjectId)
      setSelectedProject(
        mockedProjects.find((project: Project) => project.projectId === selectedProjectId) || null,
      );

    if (mockedEnvironments && selectedProjectId)
      setProjectEnvironments(
        mockedEnvironments.filter((environment: Environment) => environment.projectId === selectedProjectId),
      );
  }, [params.projectId]);

  return (
    <div className={clsx('environments', 'environments-edit-mode')}>
      {!projectEnvironments || !selectedProject ? (
        <Loading />
      ) : (
        <>
          <div className="environments-title">
            <h3>
              {texts.environments} de {selectedProject.name}
            </h3>
            <div className="environments-actions">
              <CustomButton onClick={newEnvironment}>{texts.newEnvironment}</CustomButton>
              <CustomButton variant={CustomButtonVariant.SECONDARY} onClick={backToList}>
                {texts.goBack}
              </CustomButton>
            </div>
          </div>
          <EnvironmentsTable openEnvironment={openEnvironment} projectEnvironments={projectEnvironments} />
        </>
      )}
    </div>
  );
}

export default Environments;
