import { Field, FormikHelpers } from 'formik';
import { texts } from '../data/texts';
import FormTextField from '../components/common/forms/FormTextField';
import AuthLayout from '../components/auth/AuthLayout';
import schemas from '../data/schemas';

interface LoginValues {
  email: string;
  password: string;
}

function Login() {
  const submitLogin = (values: LoginValues, { setSubmitting }: FormikHelpers<LoginValues>) => {
    setSubmitting(false);
  };

  return (
    <AuthLayout
      initialValues={{ email: '', password: '' }}
      validationSchema={schemas.LoginSchema}
      onSubmit={submitLogin}
      errorMessage={''}
      buttonContent={texts.send}
    >
      <Field label="Email" name="email" component={FormTextField} />
      <Field label="Contraseña" name="password" type="password" component={FormTextField} />
    </AuthLayout>
  );
}

export default Login;
