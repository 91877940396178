import { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import EditIcon from '../../assets/svg/edit_icon.svg';
import { Environment } from '../../types/environment.types';
import { TableItem } from '../../types/table.types';
import Loading from '../common/animations/Loading';

interface EnvironmentsTableProps {
  openEnvironment: (openEnvironment: Environment) => void;
  projectEnvironments: Environment[];
}

function EnvironmentsTable({ openEnvironment, projectEnvironments }: EnvironmentsTableProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const tableRowName: TableItem[] = [
    { row: 'environmentId', text: 'Id ambiente' },
    { row: 'url', text: 'Url' },
    { row: 'alias', text: 'Alias' },
  ];

  const environmentsRows = projectEnvironments.slice(0, 20).map((row) => {
    return (
      <TableRow key={row.environmentId} hover classes={{ root: 'table-row', hover: 'table-row-hover' }}>
        <TableCell align="center" classes={{ root: 'table-cell' }}>
          <Typography variant="body2">{row?.environmentId}</Typography>
        </TableCell>
        <TableCell align="center" classes={{ root: 'table-cell' }}>
          {row?.url}
        </TableCell>
        <TableCell align="center" classes={{ root: 'table-cell' }}>
          {row?.alias}
        </TableCell>
        <TableCell classes={{ root: 'table-cell actions-cell' }} align="right">
          <div className="icons-row-cell">
            <div className="squared-icon" onClick={() => openEnvironment(row)}>
              <img src={EditIcon} alt="edit" />
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  });

  useEffect(() => {
    //TODO: delete after endpoint integration
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Paper classes={{ root: 'table-paper-container' }} elevation={0}>
      <TableContainer className="table-container">
        <Table className="table">
          <TableHead classes={{ root: 'table-head' }}>
            {tableRowName.map((rowItem: TableItem, index: number) => (
              <TableCell key={index} classes={{ head: 'table-head-cell' }} align="center">
                {rowItem.text}
              </TableCell>
            ))}
            <TableCell classes={{ head: 'table-head-cell actions-head-cell' }} align="right" />
          </TableHead>
          <TableBody>{environmentsRows}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default EnvironmentsTable;
