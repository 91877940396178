import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { measures } from '../data/measures';
import { colors } from '../data/colors';
import Header from '../components/common/Header';
import Sidebar from '../components/common/sidebar/Sidebar';
import Login from './Login';
import Projects from './Projects';
import ProjectDetail from './ProjectDetail';
import Environments from './Environments';
import EnvironmentDetail from './EnvironmentDetail';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Rubik, sans-serif',
    },
    content: {
      flexGrow: 1,
      marginTop: measures['headerHeight'],
      position: 'relative',
      marginLeft: measures['expandedWidth'],
      backgroundColor: colors.grays.mainBackground,
      minHeight: '100vh',
    },
    loginForm: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

function Main() {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  //TODO: delete after endpoint integration
  const isLoggedIn = true;

  useEffect(() => {
    if (isLoggedIn && location.pathname === '/') navigate('/projects');
  }, [isLoggedIn, navigate, location]);

  return (
    <div className={classes.root}>
      {isLoggedIn && <Header />}
      {isLoggedIn && <Sidebar />}
      <main className={isLoggedIn ? classes.content : classes.loginForm}>
        <div className="content">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/create" element={<ProjectDetail />} />
            <Route path="/projects/:projectId/update" element={<ProjectDetail />} />
            <Route path="/projects/:projectId/environments" element={<Environments />} />
            <Route path="/environments/create" element={<EnvironmentDetail />} />
            <Route path="/environments/:environmentId/update" element={<EnvironmentDetail />} />
          </Routes>
        </div>
      </main>
    </div>
  );
}

export default Main;
