import { createStyles, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomButton, { CustomButtonVariant } from '../components/common/buttons/CustomButton';
import EnvironmentForm from '../components/environments/EnvironmentForm';
//TODO: delete after endpoint integration
import { mockedEnvironments } from '../data/mockedEnvironments';
import { texts } from '../data/texts';
import { Environment } from '../types/environment.types';

interface EnvironmentParams {
  environmentId?: string;
}

const useStyles = makeStyles(() => createStyles({ buttons: { display: 'flex', gap: 16 } }));

function EnvironmentDetail() {
  const classes = useStyles();
  const params = useParams<keyof EnvironmentParams>();
  const navigate = useNavigate();
  const [selectedEnvironment, setSelectedEnvironment] = useState<Environment | null>(null);
  const formId: string = 'environment-form-id';

  const cancel = () => navigate(-1);

  useEffect(() => {
    const selectedEnvironmentId = params.environmentId;

    if (mockedEnvironments && selectedEnvironmentId)
      setSelectedEnvironment(
        mockedEnvironments.find((environment) => environment.environmentId === selectedEnvironmentId) || null,
      );
  }, [params.environmentId]);

  return (
    <div className="environment">
      <div className="environment-title">
        <h3>{`${params.environmentId ? 'Editar' : 'Nuevo'} ambiente`}</h3>
        <div className={classes.buttons}>
          <CustomButton type="submit" form={formId}>
            {texts.save}
          </CustomButton>
          <CustomButton onClick={cancel} variant={CustomButtonVariant.SECONDARY}>
            {texts.cancel}
          </CustomButton>
        </div>
      </div>
      <EnvironmentForm selectedEnvironment={selectedEnvironment!} formId={formId} />
    </div>
  );
}

export default EnvironmentDetail;
