import { Paper } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { Environment } from '../../types/environment.types';
import schemas from '../../data/schemas';
import { texts } from '../../data/texts';
import FormTextField from '../common/forms/FormTextField';

interface EnvironmentFormProps {
  selectedEnvironment?: Environment;
  formId?: string;
}

interface EnvironmentValues {
  url: string;
  alias: string;
}

function EnvironmentForm({ selectedEnvironment, formId }: EnvironmentFormProps) {
  // const [submitting, setSubmitting] = useState<boolean>(false);

  const submitEnvironment = (values: EnvironmentValues) => console.log('TODO');

  return (
    <Paper elevation={0} className="paper">
      <Formik
        initialValues={{
          url: selectedEnvironment ? selectedEnvironment.url : '',
          alias: selectedEnvironment ? selectedEnvironment.alias : '',
        }}
        validationSchema={schemas.EnvironmentSchema}
        onSubmit={submitEnvironment}
        enableReinitialize
      >
        <Form id={formId} className="form">
          <div className="form-row">
            <Field label={texts.url} name="url" component={FormTextField} type="text" />
            <Field label={texts.alias} name="alias" component={FormTextField} type="text" />
          </div>
        </Form>
      </Formik>
    </Paper>
  );
}

export default EnvironmentForm;
