import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { colors } from '../../data/colors';
import CustomButton from '../common/buttons/CustomButton';
import AuthHeader from './AuthHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
    },
    form: {
      padding: 0,
      width: 500,
      border: `1px solid ${colors.grays.formGray}`,
      borderRadius: 10,
      overflow: 'hidden',
    },
    error: {
      display: 'inline-block',
      textAlign: 'center',
      marginTop: 30,
      color: colors.reds.errorRed,
    },
    formHeader: {
      backgroundColor: theme.palette.primary.main,
      color: colors.white,
      padding: '15px 30px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': { height: '50px' },
      '& p': {
        marginTop: 7,
        fontSize: 14,
        fontStyle: 'italic',
        color: colors.grays.formGray,
      },
    },
    formContent: {
      padding: '30px',
      '&>h3': {
        textAlign: 'center',
        marginBottom: '1rem',
        font: 'normal normal normal 30px/54px Rubik',
      },
      '&>p': { marginBlock: '0 1rem' },
      '& p': { font: 'normal normal normal 12px/24px Rubik' },
      '& button': { width: '100%' },
    },
    linkWrapper: { marginBottom: '1.5rem' },
    link: {
      color: colors.greens.plexoGreen,
      font: 'normal normal normal 12px/14px Rubik',
      '&:hover': { color: colors.greens.graphGreen },
    },
    buttonContainer: { marginTop: '1.5rem' },
  }),
);

interface AuthLayoutProps {
  initialValues: any;
  validationSchema: Yup.InferType<any>;
  onSubmit: (values: any, helpers: FormikHelpers<any>) => void;
  errorMessage: string;
  buttonContent?: string;
  linkBeforeButton?: LinkProps;
  linkAfterButton?: LinkProps;
  children?: ReactNode;
}

export interface LinkProps {
  previousContent?: string;
  linkContent: string;
  to: string;
}

function AuthLayout({
  initialValues,
  validationSchema,
  onSubmit,
  errorMessage,
  buttonContent,
  linkBeforeButton,
  linkAfterButton,
  children,
}: AuthLayoutProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form className={classes.form}>
            <AuthHeader />
            <div className={classes.formContent}>
              {children}
              {linkBeforeButton && (
                <div className={`${classes.container} ${classes.linkWrapper}`}>
                  <Link className={classes.link} to={linkBeforeButton.to}>
                    {linkBeforeButton.linkContent}
                  </Link>
                </div>
              )}
              {buttonContent && (
                <div className={classes.buttonContainer}>
                  <CustomButton type="submit" disabled={isSubmitting}>
                    {buttonContent}
                  </CustomButton>
                </div>
              )}
              {linkAfterButton && (
                <div className={classes.container}>
                  {linkAfterButton.previousContent && <p>{linkAfterButton.previousContent}</p>}
                  <Link to={linkAfterButton.to}>{linkAfterButton.linkContent}</Link>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {errorMessage && <p className={classes.error}>{errorMessage}</p>}
    </div>
  );
}

export default AuthLayout;
